/* Media Query */

@media(max-width:992px){
    .sm_right_container {
        max-width: 100%;
        margin-left: auto;
    }
    .sm_right_container *{
        pointer-events: auto !important;
    }

    /* Menu Icon */
    .backdrop_back{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.5);
        z-index: 100;
    }
    .header_menu_icon{
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #eee;
        text-align: center;
        margin: 15px 19px 0;
        border-radius: 4px;
        color: #333;
    }
    .header_menu_icon:hover,
    .header_menu_icon:focus{
        background-color:#e2dbdb;
        border-color:#e2dbdb;
    }

    .sidebar {
        top:0;
        left: -259px;
        transition:0.3s left ease-in-out;
    }
    .sidebar.active {
        left: 0;
    }
    .sm_overflow{
        overflow: auto;
    }
    .mx_width {
        min-width: 768px;
    }
}


@media(max-width:768px){

    footer .footer_section {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .unset_ml_auto{
        margin-left:unset !important ;
    }
    .sm_mb_4 {
        margin-bottom: 1rem;
    }
    .inner_width_center {
        padding: 0 25px;
    }
}

@media(max-width:720px){
    .center_container_box {
        height: calc(100vh - 264px) !important;
    }
    .sign_up_container {
        height: 100% !important;
    }
}
@media(max-width:574px){
    .inner_width_center{
         min-width: 100% !important;;
    }
}
@media(max-width:632px){

    .sm_col_flex{
        flex-direction: column;
    }
    .p_36 {
        padding: 0 15px;
    }
    .sm_btn_group {
        margin: 20px auto 0 auto;
    }
    .sm_btn_1 {
        margin-left: 0px !important;
        margin-top: 20px;
    }
    .card_body {
        padding: 22px 15px;
    }
    .card_header {
        padding: 22px 15px;
    }

}
@media(max-width:500px){
    .title{
        text-align: center;
    }
    .checkbox-left{
        margin: 0;
        display: block;
        width: auto;
    }
    .checkbox-margin{
        margin-left: 0;
    }
    .frst-btn {
        bottom: 39%;
    }
    .scnd-btn {
        bottom: 39%;
    }
    .img_box {
        width: 100%;
    }
    .form_col_sm {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .items_images {
        width: 100px;
        height: 100px;
        border-radius: 11px;
    }
    .main_head > .btn-group > .cs_btn {
        margin-left: 10px;
    }
    .form_col_sm .left_edit{
        margin-bottom: 16px;
    }
}

/* End */