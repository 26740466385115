body{
    scroll-behavior: smooth;
}

.center_container_box {
    height: calc(100vh - 182px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.inner_width_center{
    min-width: 555px;
}
.input_sign_up .form-group {
    margin-bottom: 10px;
}

.eye_icon {
    font-size: 22px;
    padding: 0 10px;
    position: absolute;
    top: 25px;
    right: -12px;
    transform: translate(-50%,-50%);
    z-index: 2;
    cursor: pointer;
}
.logo_section {
    width: 150px;
}
.header_section > div{
    height: auto !important;
}
.padding_tb_20 {
    padding: 20px 0;
}
.padding_tb_12r{
    padding: 12rem 0;
}
.log_btn {
    padding: 0.625rem 1.125rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.input_field{
    display: block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: 0.75rem 3rem 0.75rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1e2022;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d5dae2;
    border-radius: 0.3125rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-wide {
    min-width: 9.6875rem;
}
.round_border {
    border-radius: 6.1875rem;
}
.ex_space_btn {
    padding: 13px 20px !important;
}
.padding_tb_4r {
    padding: 4rem 0px;
}
.text_form{
    color: #77838f;
    line-height: 1.7;
}

/* footer section */
footer{
    padding: 2rem 0;
}
.footer_list_option ul li{
    font-size: 0.8175rem;
    margin-right: 0.5rem;
    padding-right: 1rem;
    cursor: pointer;
    text-transform: capitalize;
    color: #77838f;
}
.footer_social_icons ul li{
    font-size: 0.8175rem;
    width: 2rem;
    height: 2rem;
    position: relative;
    border-radius: 50%;
    margin-right: 0.5rem;
    cursor: pointer;
    transition: all 0.5s;
}
.footer_social_icons ul li:hover{
    background-color: #000000;
    transition: all 0.5s;
}
.footer_social_icons ul li:hover i{
    color: #ffffff;
}
.footer_social_icons ul li:last-child{
    margin-right: 0rem;
}
.footer_social_icons ul li i{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

/* End */