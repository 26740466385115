*{
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}
:root{
    --width_cs:259px;
    --clr_1:#dee2ec;
    --clr_2:#ed4c78;
    --clr_3:#00c9a7;
    --clr_4:#377dff;
    --clr_5:#71869d;
    --clr_6:#0c0c0c;
    --bg_clr_1:#f8fafd;
    --bg_clr_2:#e6eaf3;
}
body, html {
    min-height: 100vh;
}
a:hover {
    text-decoration: none;
}
/* custom class */
.over_flow_hidden {
    overflow: hidden;
}
.p_36{
    padding: 0 36px;
}
.pb_30{
        padding-bottom: 30px;
}
.header_option > li:first-child{
    margin-right: 24px;
}
.font_14{
    font-size: 14px !important;
}
.mt_17{
    margin-top: 17px;
}
.mb_40{
    margin-bottom: 40px;
}
.mt_3r {
    margin-top: 3rem !important;
}
.pt_7r {
    padding-top: 7rem !important;
}
.cs_btn {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    background: transparent;
    padding: 8px 10px;
    border-radius: 5px;
}
.unset_position{
    position: unset !important;
}
.out_line1{
    border: 1px solid var(--clr_2);
    color: var(--clr_2);
}
.out_line2{
    border: 1px solid var(--clr_5);
    color: var(--clr_5);
}
.bg_prime{
    background-color: var(--clr_4);
    color: #ffffff;
}
.bg_light{
    background-color: var(--bg_clr_2);
}
.bg_ex_light{
    background-color: #dee2ec;
}
.text_dark{
    color: var(--clr_6) !important;
}
.text_success{
    color: var(--clr_3);
}
.text_danger{
    color: var(--clr_2) !important;
}
.border_1{
    border: solid 1px var(--clr_1);
}
.focus_out:focus{
    outline: none;
    box-shadow: none;
}
[disabled] {
   border: 1px solid #dee2ec;
   color: #dee2ec !important;
  }

/* End */

/* Check Box */

.wrap_checkbox {
    display: block;
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0px;
  }
  
  .wrap_checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .check_mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: var(--clr_1);
    border-radius: 4px;
    border: solid 1px var(--clr_1);
  }
  
  .wrap_checkbox input:checked ~ .check_mark {
    background-color: var(--clr_4);
  }
  
  .check_mark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .wrap_checkbox input:checked ~ .check_mark:after {
    display: block;
  }
  
  .wrap_checkbox .check_mark:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
.check_label{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.24px;
    text-align: left;
    color: #71869d;
    top: -4px;
}
.check_label_2{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    top: -4px;
}
/* End */

/* Toggle Button */

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--clr_1);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #377dff;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #377dff;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

/* End */

/* Sidebar Section */


.sidebar {
    position: fixed;
    max-width: var(--width_cs);
    width: 100%;
    height: 100%;
    border-right: 1px solid var(--clr_1);
    z-index:1050;
    transition:0.3s all ease-in-out;
}

.sidebar_header {
    padding: 18px 0 120px 32px;
}
.sidebar_header .logo_size{
    width: 101px;
    height: 33px;
    object-fit: cover;
}
.sidebar_menu {
    height: calc(100% - 171px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sidebar_header .sub_title{
    font-size: 11px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 1.32px;
    color: var(--clr_5);
    margin-top: 7px;
    margin-left: 10px;
}
menu li {
    margin-bottom: 20px;
}
menu li > .items{
    font-size: 14px;
    line-height: 1.36;
    letter-spacing: -0.28px;
    color: #000000;
    padding: 6px 32px;
    border-left: 4px solid transparent;
    text-decoration: none;
}
menu li > .items .im_active{
    display: none;
}
menu li > .active_items {
    border-color: var(--clr_4);
    color:var(--clr_4);
}
menu li > .active_items > .in_active{
    display: none;
}
menu li > .active_items > .im_active{
    display: block;
}

menu > li > .items > img{
    width: 16px;
    height: 16px;
    margin-right:11px;
}
.qr_section {
    width: 120px;
    object-fit: contain;
    margin: 0 auto;
}
.qr_section > p {
    color: var(--clr_4);
    padding-top: 10px;
    font-size: 13px;
}
.sidebar_footer {
    border-top: 1px solid var(--clr_1);
    padding:30px 0px;
}

.sidebar_footer i{
    font-size: 16px;
    margin-right: 11px;
}
/* End */




.right{
    max-width:calc(100% - var(--width_cs));
    margin-left: var(--width_cs);
}

/* Header Section */
header > div{
    height: 58px;
    border-bottom: 1px solid var(--clr_1);
}

.notification span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--clr_2);
    position: absolute;
    right: -12px;
    top: -10px;
}
.user_icon{
    width: 36px;
    height: 36px;
}
.user_icon > span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--clr_3);
    position: absolute;
    right: 0;
    bottom: -1px;
    border: 2px solid #fff;
}

/* End */

/* main */
.main_wrap_checkboxs {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 58px);
    justify-content: space-between;
}
.mn-height {
    min-height: calc(100vh - 99px);
}
.title {
    margin-top: 37.4px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.38;
    letter-spacing: -0.72px;
    color: #000000;
  }
  .sub_title {
    margin-top: 9px;
    font-size: 14px;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--clr_5);
    margin-bottom: 0;
  } 
/* Main content */
/* .main-wrapper{
    min-height: calc(100vh - 58px);
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}
.center_box{
    flex-grow: 1;
    display: flex;
    align-items: center; 
}

.main-footer{
    margin-top:auto;
} */
/* .wrapper_card_img{ 
    width: 463px;
    height:auto; 
} */

.center_box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}
.img_box{
    width: 463px;
    margin: 0 auto;
}



/* Live offer */

.main_head{
    padding-top: 51px;
    padding-bottom: 30px;
}
.main_head > .btn-group > .cs_btn{
    margin-left: 24px;
}
.card_header {
    padding: 22px 75px;
    background-color: #f8fafd;
    border-bottom: 1px solid #e7e8ec;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px ;
}
.card_body{
    padding: 22px 75px;
}
.card_container{
    border: 1px solid #e7e8ec;
    border-radius: 11px;
}
/* End */

/* My Store */

.container_table.tbl_border{
    border: 1px solid #e7e8ec;
    border-radius: 11px;
}
.container_table > table > thead > tr > th{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.24px;
    text-align: left;
    color: #71869d;
    position: relative;
    padding: 20px 22px;
    border-bottom: 1px solid #e7e8ec;
    border-top: 0px;
    background-color: #f8fafd;
}

.container_table > table > thead > tr > th > .wrap_checkbox .check_mark {
    top: 50%;
    transform: translate(0%,-50%);
    left: 20px;
}
.container_table > table > tbody > tr > td {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    position: relative;
    padding: 20px 22px;
    border: 0px;
}
.container_table > table > tbody > tr > td > .wrap_checkbox .check_mark {
    top: 50%;
    transform: translate(0%,-50%);
    left: 20px;
}
.container_table > table > tbody > tr > td i{
    font-size: 17px;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
}
.img_avtar {
    width: 42px;
    height: 42px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
}
.img_fluid_table{
    width: 100%;
    height: 100%;
}
.pd_left_right{
    padding: 8px 10px;
}
/* End */

/* Edit Item */
.items_images{
    width: 129px;
    height: 129px;
    border-radius: 11px;
}
.card_edit {
    padding: 38px 21px;
}

.items_text{
    font-size: 14px;
    line-height: 1.36;
    letter-spacing: normal;
    color: #000000;
    font-weight: 600;
}

.items_text_sub{
    font-size: 14px;
    line-height: 1.36;
    letter-spacing: normal;
}
.left_edit {
    max-width: 217px;
    width: 100%;
}
.cs_input {
    min-height: 42px;
    border-radius: 6px;
    height: auto;
}
.cs_input::placeholder{
    color: #71869d;
    font-weight: 400;
}
.edit_btn {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff;
    border: 0;
    position: absolute;
    bottom: -8px;
    font-size: 13px;
    right: 0px;
    box-shadow: 0 3px 3px 0 rgb(222 226 236 / 56%);
    display: flex;
    justify-content: center;
    align-items: center;
}
/* End */


/* Setting */
.header_title{
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #0c0c0c;
}
.card_header1 {
    border-bottom: solid 1px #dee2ec;
    padding: 17px 21px;
}
.card_wrap{
    border: 1px solid #e7e8ec;
    border-radius: 11px;
}
.card_body_1{
    padding: 21px 25px;
}

.cs_button {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #377dff;
    background-color: transparent;
    border: 0;
    padding: 0;
    padding-left: 15px;
    margin-top: 13px;
}
.btn_focus:focus{
    outline: none;
}

/* End */


/* Business Profile */

.card_header2 {
    min-height: 160px;
    border-top-right-radius: 11px;
    border-top-left-radius: 11px;
}
.border_top{
    border-top: 0px;;
}
.avtr_box {
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translate(-50%,0%);
}
.card_head{
    padding: 23px 21px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("../image/down.svg") no-repeat;
    width: 7px;
    height: 7px;
    background-position: center;
    background-size: contain;
    border-width: thin;
    cursor: pointer;
}
.upload_input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 2;
}
/* End */

.pointer{
    cursor: pointer;
}


/* footer */

.main-footer p {
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: normal;
    color: var(--clr_5);
    margin-bottom: 0;
}
.main-footer {
    margin-bottom: 24px;
}

/* Center Div */
.position_center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
}

.custom_card{
    border: 2px solid #3795fa;
    border-radius: 30px;
}

.custom_card2{
    border: 2px solid #fff;
    border-radius: 30px;
}

/* Toggle Button */
.cm-toggle {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	border: 0;
	outline: 0;
	cursor: pointer;
}


/* To create surface of toggle button */
.cm-toggle:after {
	content: '';
	width: 55px;
	height: 22px;
	display: inline-block;
	background: rgba(196, 195, 195, 0.55);
	border-radius: 18px;
	clear: both;
}


/* Contents before checkbox to create toggle handle */
.cm-toggle:before {
	content: '';
	width: 28px;
	height: 28px;
	display: block;
	position: absolute;
	left: 0;
	top: -3px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	box-shadow: 1px 1px 6px rgba(10, 10, 10, 0.6);
}

/* Shift the handle to left on check event */
.cm-toggle:checked:before {
	left: 28px;
    box-shadow: -1px 1px 6px rgb(10 10 10 / 60%);
}
/* Background color when toggle button will be active */
.cm-toggle:checked:after {
	background: #387eff;
}

button:focus{
    outline: none;
}

.qr-settings {
    width: 100%;
    margin: 5vh 0;
}

.checkbox-left {
    display: contents;
}

.checkbox-margin{
    margin-left: 3rem;
}

.mobile-sepration-main {
    display: flex;
  }
  @media (max-width: 600px) {
    .mobile-sepration-main {
      display: block;
    }
    .mobile-sepration-main .mobile-sepration {
      display: block;
    }
    .mobile-sepration-main .mobile-sepration:last-child input {
      margin-left: 0 !important;
      margin-top: 0.5rem;
    }
    .avtr_box {
      width: 34%;
      text-align: center;
    }
    .items_images {
      margin: auto;
    }
    .custom-spacing {
      margin-left: 1rem !important;
    }
  }
  @media (max-width: 800px) {
    .remove-bdr {
      border-right: 0px !important;
    }
}
