.main-outer {
  width: 100%;
  font-size: 22px;
  border-radius: 8px;
}
.bg-gray-color {
  background: rgba(0, 0, 0, 0.02);
}
.main-outer svg {
  margin:0 20px;
  vertical-align: revert;
}

.update-btn {
  float: right;
  color: white;
  background-color: rgb(126, 126, 126);
  text-align: center;
  text-shadow: none;
}

.items-section {
  font-size: 22px;
  margin-left:3rem;
}

.item-border {
  border-bottom: 1px solid rgba(236, 236, 236, 0.5);
  padding: 10px 0;
}

.item-border:last-child {
  border-bottom: none;
}

.frst-btn {
  position: absolute;
  bottom: 45%;
  z-index: 2;
  left: 0;
}

.scnd-btn {
  position: absolute;
  bottom: 45%;
  z-index: 2;
  right: 0;
}

.owl-button > button {
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -khtml-border-radius: 50%;
    border: 3px solid #fff;
}

.owl-button svg {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  vertical-align: middle;
}

/* .owl-button > button {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  outline:none;
  border: none;
  padding: 5px;
  background-color: white;
  -webkit-box-shadow : 2px 3px 20px rgb(182, 182, 182);
  box-shadow: 2px 3px 20px rgb(182, 182, 182);
}

.owl-button > button:focus {
  border-radius:100%;
  -webkit-border-radius: 100%;
}

.owl-button svg {
  border-radius:100%;
  -webkit-border-radius: 100%;
  -webkit-svg-shadow : none
}

.owl-button svg:focus {
  border-radius:100%;
  -webkit-border-radius: 100%;
}

.owl-button svg:hover {
  -webkit-border-radius: 100%;
  border-radius:100% !important;
  fill: white;
  background-color: black;
  transition-duration: 300ms;
  box-shadow: 2px 3px 20px rgb(99, 99, 99);
  -webkit-box-shadow : 2px 3px 20px rgb(99, 99, 99);
  -webkit-transition-duration: 300ms;
} */