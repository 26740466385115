* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.contain {
  width: 100%;
  /* height: 900px; */
  text-align: center;
  font-family: Calibri, sans-serif;
}

section .menu ul li {
  list-style: none;
  padding: 5px 0;
}

section .menu .text-start {
  /* height: 500px; */
  overflow-y: auto;
  margin-top: 2rem !important;
}
section .menu ul li button {
  width: 80%;
  border: 1px solid white;
  border-radius: 5px;
  text-align: start;
  /* padding-left: 5px; */
  padding: 5px 10px;
  text-align: start;
}
section .menu ul li button:focus {
  background-color: rgb(194, 194, 194);
}

section .card .card_body {
  display: flex;
  flex-direction: column;
  padding-right: 35px;
}
.card_body {
  padding: 0 2.5rem 0 1rem !important;
}

section .card .card_body button {
  width: 100%;
}

/* modal */
.modal-content {
  /* width: 1000px; */
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  section .card .card_body {
    padding-right: 50px;
  }
  section .menu {
    padding: 0px;
  }
}

@media only screen and (max-width: 992px) {
  section .card .card_body {
    padding-right: 25px;
  }
}
@media only screen and (max-width: 991px) {
  section .card .card_body {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 768px) {
  section .menu ul li button {
    width: 100%;
    text-align: center;
  }
  section .card .card_body {
    padding-right: 0px;
  }
  .modal .heading p {
    margin: 0px;
    padding: 0px;
  }
}
@media only screen and (max-width: 576px) {
  .modal .heading p {
    margin: 0px;
    padding: 0px;
  }
  section .card .card_body {
    padding-left: 0px;
  }
  /* section .menu .text-start {
    height: 100px;
  } */
  .videoBtn a {
    top: 35%;
    left: 25%;
  }
}

@media only screen and (max-width: 390px) {
  .videoBtn a {
    top: 35%;
    left: 25% !important;
  }
}

.libraryCard {
  transition: transform 0.2s;
  z-index: 10;
  box-shadow: rgb(50 50 93 / 25%) 0px 20px 30px -130px,
    rgb(0 0 0 / 30%) 0px 10px 10px -10px,
    rgb(10 37 64 / 20%) 0px -2px 6px 0px inset;
}

.libraryCard:hover {
  transform: scale(1.03);
}
.videoBtn {
  position: relative;
}
.videoBtn a {
  height: 100%;
  width: 100%;
  position: absolute;
  display: block;
  background: url(../../Assets/image/play_circle_filled.png);
  background-repeat: no-repeat;
  top: 35%;
  left: 33%;
}
